.item-feature-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.item-feature {
  position: relative;
  min-height: 60px;
  /* width: 25%; */
  padding-right: 15px;
}

.item-feature .item-feature__icon {
  position: absolute;
  top: -11px;
  left: 0;
  font-size: 30px;
  width: 60px;
  color: #555555;
}

.item-feature .item-feature__text-content {
  padding-left: 40px;
}

.item-feature .item-feature__title {
  margin-bottom: 0;
  font-size: 15px;
  line-height: 0.6;
}

.item-feature .item-feature__sub-title {
  font-size: 13px;
  line-height: 1;
}

/* massage card css */
.phone-box {
  padding: 5px;
  margin: 5px;
}
.phone-number {
  float: left;
  width: 60%;
}
.contact-logo {
  width: 35%;
  position: relative;
  float: right;
}
.contact-logo img {
  height: 40px;
  width: 65px;
}


