@import "~react-image-gallery/styles/css/image-gallery.css";

.default-text {
  font-size: 18px;
}

.main-header {
  height: auto;
  line-height: 15px;
}


